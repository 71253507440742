import { createSlice, nanoid } from '@reduxjs/toolkit'
import { sub } from 'date-fns';

const initialState = {
  visits: [
    { name: 'JD', visitStep: 'Visit Complete', messages: 4, physician: { name: 'Dr.Bernard' }, stepColor: 'bg-base-100', visitDate: sub(new Date(), { minutes: 10 }).toISOString(), },

    { name: 'AB', visitStep: 'Waiting Room', messages: 2, physician: { name: 'Dr.Wong' }, stepColor: 'bg-green-100', visitDate: sub(new Date(), { minutes: 5 }).toISOString(), }
  ]
}

const getInitials = function (string) {
  let names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};


 const visitsSlice = createSlice({
    name: 'visits',
    initialState,
    reducers: {

      visitAdded: {
        reducer(state, action) {
          state.visits.push(action.payload)
        },

        prepare(enrollment, name, healthCardNumber, recordNumber, birthDate) {
          return {
            payload: {
              id: nanoid(),
              name: getInitials(name),
              healthCardNumber,
              recordNumber,
              birthDate,
              visitStep: "Intake Form", visitTime: "0:21", messages: 0, stepColor: 'bg-red-200', physician:
              {
                name: "",
                id: "",
                timestamp: ''
              },
              enrolment_type: enrollment,
              status: 'active',
              visitDate: new Date().toISOString(),
             
            }
          }

        }

      },
      updateVisitStatus: (state, action) => {
        const { id } = action.payload;
        const visit = state.visits.find((visit) => visit.id === id);
        if (visit) {
          visit.status = 'notActive';
        }
      }
    }
})

export const selectAllVisits = (state) => state.visits;

export const { visitAdded, updateVisitStatus, } = visitsSlice.actions

export default visitsSlice.reducer