import { configureStore } from '@reduxjs/toolkit'

import rightDrawerReducer from '../features/common/rightDrawerSlice'
import headerReducer from '../features/common/headerSlice'
import modalReducer from '../features/common/modalSlice'
import visitsReducer from '../features/visits/visitsSlice'
import pastVisitsReducer from '../features/pastvisits/pastVisitsSlice'

export const store = configureStore({
    reducer: {    
      header: headerReducer,
      modal: modalReducer,
      rightDrawer: rightDrawerReducer,
      visits: visitsReducer,
      pastVisits: pastVisitsReducer
  }
})