import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getPastVisits = createAsyncThunk('/pastVisits/content', async () => {
    const response = await axios.get('https://reqres.in/api/users?page=2', {})
	return response.data;
})

export const pastVisitsSlice = createSlice({
    name: 'pastVisits',
    initialState: {
        isLoading: false,
        pastVisits : []
    },
    reducers: {

        deletePastVisit: (state, action) => {
            let {index} = action.payload
            state.pastVisits.splice(index, 1)
        }
    },

    extraReducers: {
        [getPastVisits.pending]: state => {
			state.isLoading = true
		},
        [getPastVisits.fulfilled]: (state, action) => {
			state.pastVisits = action.payload.data
			state.isLoading = false
		},
        [getPastVisits.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { deletePastVisit } = pastVisitsSlice.actions

export default pastVisitsSlice.reducer